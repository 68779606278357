.home-bg {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--primary-accent);
    background-color: var(--primary-bg);
}

.home-text {
    font-size: 4rem;
    text-align: center;
}

.home-description {
    font-size: 24px;
    text-align: center;
}